<template>
  <div class="req-temp-container client-user-form">
    <vs-card v-if="formData.userRole != 'apiUser'">
      <div slot="header">
        <h3 class="font-normal">Details</h3>
      </div>
      <vs-row>
        <vs-col vs-w="6" vs-lg="4" vs-sm="12">
          <div class="input-container mb-5">
            <span class="text-danger text-sm" v-show="errors.has('fullName')">{{ errors.first("fullName") }}</span>
            <vs-input v-validate="'required'" v-model="formData.fullName" name="fullName" :v-validate="'fullNameRules'" />
            <label class="w-full text-base">Name</label>
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col vs-w="6" vs-lg="4" vs-sm="12" class="mb-5">
          <div class="input-container">
            <span class="text-danger text-sm" v-show="errors.has('email')" >{{ errors.first("email") }}</span>
            <vs-input class="w-full" v-validate="'required|email'" size="large" v-model="formData.email" name="email" />
            <label class="w-full text-base">Email</label>
          </div>
        </vs-col>
        <vs-col vs-w="6" vs-lg="4" vs-sm="12">
          <div class="input-container">
            <span class="text-danger text-sm" v-show="errors.has('phoneNumber')">{{ errors.first("phoneNumber") }}</span>
            <the-mask
              class="lg:w-3/4 w-full md:w-3/4 vs-inputx vs-input--input large"
              :mask="['#### ### ###']"
              v-validate="{ required: true, regex: /^04\(?\d{2}\)?[\s.-]\d{3}[\s.-]\d{3}$/ }"
              v-model="formData.phoneNumber"
              masked
              name="phoneNumber"
              ref="phoneNumber"
            />
            <label class="w-full text-base">Mobile</label>
          </div>
        </vs-col>
      </vs-row>
      <vs-row class="mb-10">
        <label class="w-full text-base mb-3">User's role</label>
        <vs-radio v-model="formData.userRole" vs-value="staffAdmin" vs-name="userRole" @change="activate('staffAdmin')" :disabled="disableRoleOption" class="text-body mr-20">Admin</vs-radio>
        <vs-radio v-model="formData.userRole" vs-value="staff" vs-name="userRole" @change="activate('staff')" :disabled="disableRoleOption" class="text-body ml-20">User</vs-radio>
      </vs-row>
      <div class="mb-4 mt-10" v-if="formData.userRole != 'apiUser'">
        <div class="flex mb-4">
          <vs-switch class="mr-5" v-model="formData.viewDisbursement" name="viewDisbursement" />
          <label class="w-full text-base">View disbursements</label>
        </div>
        <div class="flex mb-4">
          <vs-switch class="mr-5" v-model="formData.viewApiActivity" name="apiActivity" @change="handleToggleChange" />
          <label class="w-full text-base">View API activity</label>
        </div>
        <span class="text-danger text-sm mt-2 w-full" v-show="errors.has('products')">
          {{ errors.first("products") }}
        </span>
        <div v-if="formData.viewApiActivity">
          <label>View API requests enabled with selected payment/finance options<span class="mid-blue">*</span></label>
          <div class="my-4" v-for="(plan, index) in products" :key="index">
            <vs-checkbox v-model="plan.checked" name="financeOption" @change="handleProductChange(plan)" v-validate="'required'">{{ getPlanName(plan) }}</vs-checkbox>
          </div>
        </div>
      </div>
    </vs-card>

    <vs-row class="mt-8">
      <vs-col vs-w="12" class="flex justify-end">
        <vs-button size="large" class="primary mr-8" @click="saveUser" v-round>Save</vs-button>
        <vs-button
         v-if="userID && (user.userType === 'admin' || user.userType === 'staffAdmin')
          && (formData.userType === 'staff' || formData.userType === 'staffAdmin' || formData.userType === 'apiUser')
          && (user._id !== userID)"
         size="large"
         @click="popupDelActive = true"
         class="only-border-btn mr-8"
         v-round>
        Delete user
        </vs-button>
        <a color="danger" @click="onCancel" class="edit-profile flex items-center text-base" v-round>Cancel</a>
      </vs-col>
    </vs-row>

    <leave-popup :popup-active="popupActive" @handleClick="popActions"></leave-popup>

    <pop-up :errorsList="errors" form-name="UserForm"></pop-up>

    <delete-popup :popup-active="popupDelActive" :warningContent="warningContent" :deleteWhat="deleteWhat" :buttonText="buttonText" :found="warningFound" @handleDeleteClick="delActions"></delete-popup>

  </div>
</template>
<script>
import { mapActions } from "vuex";
import { Validator } from "vee-validate";
import { TheMask } from "vue-the-mask";
import LeavePopup from "@/views/components/LeavePopup";
import PopUp from "@/views/components/ValidationPopup.vue";
import { XIcon } from "vue-feather-icons";
import DeletePopup from "@/views/components/DeletePopup";
import { leavePopUpMixin } from "@/mixins/leave-pop-up";

const dictionary = {
  en: {
    attributes: {
      phoneNumber: "mobile number",
      fullName: "name",

    },
    messages: {
      regex: () => "Mobile number should start with 04 and should be 10 digits",
    },
  },
};
Validator.localize(dictionary);
export default {
  mixins: [leavePopUpMixin],
  components: { TheMask, PopUp, LeavePopup, XIcon, DeletePopup },
  data() {
    return {
      userID: "",
      popupActive: false, // determines if the leave popup is to be shown
      showValidationPopUp: false, // determines if the leave popup is to be shown
      isSaved: false,
      nextObj: "", // determines the next route,
      counterDanger: "",
      formData: {
        userRole: "staffAdmin",
        userType: "admin",
        phoneNumber: "",
        email: "",
        fullName: "",
        viewApiActivity: true,
        productAccess: [],
        viewDisbursement: true
      },
      popupDelActive: false,
      warningFound: true,
      warningContent: "By deleting this user you are permanently removing their access to this platform.",
      deleteWhat: "user",
      buttonText: "Delete user",
      id: "",
      checkFormDirty: false,
      disableRoleOption: false,
      selectedUser: "",
      products: [],
    };
  },
  methods: {
    ...mapActions("partner", [
      "fetchStaffDetailsById",
      "updateStaffDetailsById",
      "createStaff",
      "deleteStaff",
    ]),
    ...mapActions("admin", ["checkLogin"]),

    onCancel() {
      this.isSaved = true;
      this.$router.push({ name: "partner-users" });
    },

    activate(el) {
      if (this.disableRoleOption) {
        return;
      }

      this.formData.userRole = el;
      this.formData.viewDisbursement = (el == "staffAdmin");
      this.checkFormDirty = true;
    },

    async getUserById(id) {
      this.$vs.loading();
      await this.fetchStaffDetailsById(id).then((result) => {
        this.selectedUser = result.data.data;
        this.formData.fullName = result.data.data.fullName;
        this.formData.email = result.data.data.email;
        this.formData.phoneNumber = result.data.data.phoneNumber;
        this.formData.userRole = result.data.data.userType === "admin" ? "staffAdmin" : result.data.data.userType;
        this.formData.userType = result.data.data.userType;
        this.formData.viewApiActivity = result.data.data.viewApiActivity;
        this.formData.productAccess = result.data.data.productAccess;
        this.formData.viewDisbursement = result.data.data.viewDisbursement;
        // Disable role option if user is admin
        if (result.data.data.userType === "admin") {
          this.disableRoleOption = true;
        }
      }).catch((ex) => {
        if ([404, 400].includes(ex.response.status)) {
          this.showMessage("Staff", ex.response.data.message, "danger", "icon-alert-circle");
          this.$router.push({ name: "partner-users" });
        }
      });
      this.$vs.loading.close();
    },

    async saveUser() {
      this.$validator.validateAll().then(async (result) => {
        const selectedProducts = this.products.filter(item => item.checked);

        if (!["apiUser"].includes(this.formData.userRole) && this.formData.viewApiActivity && !selectedProducts.length) {
          this.errors.add({
            field: "products",
            msg: "Please select at least one product"
          });

          return false;
        }

        if (result) {
          this.formData.accountStatus = "Active";

          // Updated user role if selected user is super admin
          if (this.selectedUser && this.selectedUser.userType === "admin") {
            this.formData.userRole = "admin";
          }

          if (this.formData.userRole == "apiUser") {
            delete this.formData.email;
          }

          this.formData.productAccess = selectedProducts.map((data) => {
            return {
              productId: data._id,
              type: data.type
            };
          });

          let obj = {
            id: this.$route.params.id,
            data: this.formData,
          };

          if (this.$route.params.id) {
            if (this.user && this.user.adminId && this.user.loginBy) {
              obj.data.editedByAdmin = this.user.adminId;
            }
            this.updateUser(obj);
          } else {
            if (this.user && this.user.adminId && this.user.loginBy) {
              obj.data.createdByAdmin = this.user.adminId;
            }
            this.storeUser(obj);
          }

          this.isSaved = true;
        }
      });
    },

    async updateUser(obj) {
      this.$vs.loading();
      await this.updateStaffDetailsById(obj).then((result) => {
        this.$vs.loading.close();
        this.showMessage("Successful", "User has been updated successfully.", "success", "icon-check-circle");
        this.isSaved = true;
        this.$router.push({ name: "partner-users" });
      }).catch((ex) => {
        if (ex.response.status == 422 && ex.response.data.title == "Email") {
          this.errors.add({
            "field" : "email",
            "msg": ex.response.data.message
          });
        } else {
          this.showMessage("Error", ex.response.data.message, "danger", "icon-times");
        }
        this.$vs.loading.close();
      });
    },

    async storeUser(obj) {
      this.$vs.loading();
      await this.createStaff(obj).then((result) => {
        this.$vs.loading.close();
        this.showMessage("Successful", "User has been created successfully.", "success", "icon-check-circle");
        this.isSaved = true;
        this.$router.push({ name: "partner-users" });
      }).catch((err) => {
        this.$vs.loading.close();
        this.showMessage("Error", err.response.data.message, "danger", "icon-times");
      });
    },

    showMessage(title = "Successful", message = "Task completed", color = "success", icon = "icon-check-circle") {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,
      });
    },

    delActions(event) {
      switch (event) {
        case "delete":
          this.popupDelActive = false;
          this.handleDelete();
          break;
        case "cancel":
          this.popupDelActive = false;
          break;
        default:
          this.popupDelActive = false;
      }
    },

    async handleDelete() {
      this.$vs.loading();
      await this.deleteStaff(this.id).then((result) => {
        this.isSaved = true;
        this.$vs.loading.close();
        this.showMessage("Successful", result.data.message, "success", "icon-check-circle");
        this.$router.push({ name: "partner-users" });
      }).catch((ex) => {
        this.$vs.loading.close();
        this.showMessage("Error", ex.response.data.message, "danger", "icon-times");
      });
    },

    async checkAdminLogin() {
      this.$vs.loading();
      await this.checkLogin().then((result) => {
        const plans = result.data.data.plans.map((item) => {
          return {
            _id: item._id,
            customPlanId: item.customPlanId,
            productName: item.productName,
            type: "product",
            checked: !this.isEdit(),
          };
        });

        if (result.data.data.payNowOptions && result.data.data.payNowOptions.length) {
          this.products.push({
            productName: "Pay now",
            type: "pay-now",
            checked: !this.isEdit(),
          });
        }

        this.products = [ ...this.products, ...plans ];
        this.products = this.products.map((item) => {
          if (this.formData.productAccess && this.formData.productAccess.length) {
            let data = null;

            if (item.type == "pay-now") {
              data = this.formData.productAccess.find(product => product.type == "pay-now");
            } else {
              data = this.formData.productAccess.find(product => product.productId == item._id);
            }

            return {
              ...item,
              checked: data ? true : false,
            };
          } else {
            return item;
          }
        })
      });
      this.$vs.loading.close();
    },

    isEdit() {
      return this.$route.params.hasOwnProperty("id") && this.$route.params.id.length > 0;
    },

    getPlanName(data) {
      let name = "";

      if (data.type == "pay-now") {
        name = data.productName;
      } else {
        name = `${data.productName} (${data.customPlanId})`;
      }

      return name;
    },

    handleProductChange() {
      if (this.errors.has("products")) {
        const selectedProducts = this.products.filter(item => item.checked);

        if (selectedProducts.length) {
          this.errors.remove("products");
        }
      }
    },

    handleToggleChange() {
      if (this.formData.viewApiActivity) {
        this.handleProducts(true);
      } else {
        this.handleProducts(false);

        if (this.errors.has("products")) {
          this.errors.remove("products");
        }
      }
    },

    handleProducts(isChecked) {
      this.products = this.products.map((item) => {
        return {
          ...item,
          checked: isChecked
        }
      });
    }
  },

  beforeRouteLeave(to, from, next) {
    if ((this.isFormDirty || this.checkFormDirty) && !this.isSaved) {
      this.nextObj = next;
      this.popupActive = true;
      localStorage.setItem("redirectTo", to.name || undefined);
    } else {
      next();
    }
  },
  watch: {
    isFormDirty(latest, previous) {
      if (latest) localStorage.setItem("formFieldUpdated", "yes");
    },

    isSaved(latest, previous) {
      if (!latest) localStorage.setItem("formFieldUpdated", "yes");
    },

    checkFormDirty(latest, previous) {
      if (latest) localStorage.setItem("formFieldUpdated", "yes");
    },
  },

  async mounted() {
    if (this.$route.params.id) {
      this.userID = this.$route.params.id;
      await this.getUserById(this.$route.params.id);
    }
    this.checkAdminLogin();
  },
  created() {
    this.$validator.extend("fullNameRules", {
      getMessage: () => "The name should consist at least two words.",
      validate: (value) => value.match(/^[a-z]+(?:\s[a-z]+)+$/) !== null,
    });

    this.id = this.$route.params.id;
  },

  computed: {
    user() {
      return this.$store.state.AppActiveUser;
    },

    leavePage() {
      return this.popupAnswer;
    },

    partnerId() {
      return this.user.userType === "admin" ? this.user._id : this.user.partnerId;
    },
  },
};
</script>

